@import '../../mediaQueries'
@import '../../colorScheme'
@import '../../constants'

.search
  width 100%
  max-width 900px
  margin 3em auto 0

  *
    box-sizing border-box

  button
    background transparent
    border none
    cursor pointer
    color white

  .inputWrapper
    position relative
    display flex
    width calc(100% - 45px)
    height 40px
    margin 0 auto

    // Animation disabled to test page speed improval
    // animation-name slideIn
    // animation-duration .5s
    &.hasLine
      &:before
        content ''
        position absolute
        left -10px
        top 7px
        width 1px
        height 26px
        background-color white
    &:nth-child(n+2)
      &:after
        content 'vs'
        position absolute
        font-size 12px
        left -15px
        top -8px
        color white
    @media(max-width $tablet)
      flex-direction column

    .compareBtn
      height 90%
      width 240px
      margin-left 1em
      border-radius $mediumBorderRadius
      &.landing
        border 2px solid lightgrey
        box-shadow 0px 0px 10px 0px rgba(239, 239, 239, 0.7)
        &:hover
          box-shadow 0px 0px 10px 0px rgba(239, 239, 239, 1)
      @media(max-width $tablet)
        min-height 32px
        width 80%
        margin 1em auto 0

    .trash
      position absolute
      right 3px
      top 50%
      transform translate(0, -50%)
      background transparent
      &:hover
        color red

    input[type=text]
      width 100%
      padding .5em 40px .5em 0
      height 90%
      border none
      border-bottom 1px solid white
      background transparent
      color white

    // Animation disabled to test page speed improval
    // @keyframes slideIn
    //   0%
    //     height 0
    //     opacity 0
    //   50%
    //     height 40px
    //   100%
    //     opacity 1

  @import './Home'
