@import '../colorScheme'
@import '../mediaQueries'

.addingObject
  position fixed
  top -100px
  transition transform .5s ease
  z-index 101

.comparisonList
  position fixed
  bottom 1em
  right calc((100% - 1300px) / 2)
  box-sizing border-box
  transform translateX(-1em)
  z-index 100
  &.comparison
    transition transform .2s cubic-bezier(0.55, 0.06, 0.68, 0.19)
    &.moveUp
      transform translate(-1em, -3em)
      @media(max-width $tablet)
        transform translate(-1em, -80px)

  @media(max-width 1300px)
    right 0
  @media(max-width $tablet)
    bottom 0.69em
    right -0.2em
    width 85px
    &.open
      width initial
  &.hide
    height 0
    visibility hidden

  .header
    display flex
    align-items center
    padding .5em 1em
    border-radius 20px 20px 0
    background $backBlue
    color white
    cursor pointer
    box-shadow 0 0 4px 4px rgba(0, 0, 0, .1)

    .vs
      font-weight bold
      font-size 20px
      padding-right .5em
      padding-bottom 3px
      transition transform .2s
      @media(max-width $tablet)
        padding-right .25em

    .title
      width 160px
      padding-left .5em
      padding-right 1em
      border-left 1px solid white
      @media(max-width $tablet)
        display none

    .titleMobile
      padding-left .25em
      &.open
        width 180px
      @media(min-width $tablet)
        display none

    svg
      transform rotate(270deg)
      transition transform .3s
      @media(max-width $tablet)
        display none

    &.open
      svg
        transform rotate(90deg)
        @media(max-width $tablet)
          display block

  .body
    background-color white
    margin-left 20px
    border-radius 0 0 20px 20px
    box-shadow 0 0 4px 4px rgba(0, 0, 0, .1)
    height 0
    transition height .3s
    overflow hidden

    ul
      list-style none
      margin 0
      padding 0
      max-height 188px
      box-shadow inset 0 0 4px 1px rgba(0, 0, 0, .1)
      overflow-y auto
      &::-webkit-scrollbar
        width 8px
      &::-webkit-scrollbar-track
        width 8px
        background-color #e7e8e7
        border-radius 8px
      &::-webkit-scrollbar-thumb
        width 8px
        background-color lightgrey
        border-radius 8px
    li
      position relative
      display flex
      align-items center
      padding .5em 30px .5em 0
      margin 0 1em
      border-bottom 1px solid $lightGray
      animation-name appear
      animation-duration .25s
      overflow ellipsis
      &:last-child
        border none
      @keyframes appear
        0%
          padding .5em 30px .5em 0
        50%
          padding .6em 30px .5em 0
        100%
          padding .5em 30px .5em 0

      .image
        position relative
        height 30px
        width 45px
        margin-right .5em

      img
        position absolute
        max-height 100%
        max-width 100%
        top 50%
        left 50%
        transform translate(-50%, -50%)

      .name
        max-width 130px
        font-size 13px
        display -webkit-box
        -webkit-line-clamp 2
        -webkit-box-orient vertical
        overflow hidden
        text-overflow ellipsis

      .trash
        position absolute
        right 4px
        top 50%
        transform translateY(-49.5%)
        display flex
        height 22px
        width 22px
        margin-left 1em
        padding 0
        cursor pointer
        border none
        svg
          margin auto

  .buttonContainer
    display flex
    justify-content space-between
    align-items center
    border-radius 0 0 20px 20px
    margin 0 1em 0 2em
    button
      height 30px
      margin .5em 0
      border-radius 20px
    .addButton
      cursor pointer
      background-color $coolBlue
      border 1px solid $coolBlue
      color white
      width 30px
      &:hover
        background-color white
        color $coolBlue



