@import '../../constants'

.modalContainer
  position fixed
  left 50%
  top 50%
  height 100vh
  width 100vw
  z-index 210
  cursor default
  transform translate(-50%, -50%)

  *
    box-sizing border-box

  .background
    position absolute
    left 0
    top 0
    height 100%
    width 100%
    background #0008

  .modal
    position absolute
    left 50%
    top 50%
    width 95%
    max-width 1020px
    height 95%
    background white
    border-radius $largeBorderRadius

  &.noAnimation
    .modal
      transform translate(-50%, -50%)

  &.enter
   .background, .modal
     opacity 0
   .modal
     transform translate(-50%, -50%) scale(.6, .6)
  &.enterDone
   .background, .modal
     opacity 1
     transition transform .2s, opacity .2s
   .modal
     transform translate(-50%, -50%) scale(1, 1)
  &.exit
   .background, .modal
     opacity 0
     transition transform .2s, opacity .2s
   .modal
     transform translate(-50%, -50%) scale(.6, .6)
