@import '../mediaQueries'
@import '../colorScheme'
@import '../constants'

.login
  display flex
  justify-content flex-start
  height 100%
  width 100%

  .illustration
    width 34%
    height 100%
    margin 0
    background $backBlue
    background-image url('https://images.versus.io/login_background.png'), linear-gradient(298deg, #7600e0, #3c59fc)
    background-size 100%
    background-position center
    background-repeat no-repeat
    background-blend-mode multiply
    @media(max-width $tablet)
      display none

  .content
    max-width 66%
    padding 3em
    margin auto 0
    @media(max-width $tablet)
      max-width 100%

  h3
    font-size 28px
    margin-top 0
  p
    line-height 1.4

  .icons
    display flex
    flex-direction column
    width 250px
    button
      display flex
      align-content center
      border 1px solid $darkGray
      border-radius $fullBorderRadius
      cursor pointer
      color $darkGray
      padding 10px
      margin-top 2em
      &:active,&:focus
        outline none
      &:first-child
        color $facebook
      &:last-child
        color $google
      &:hover
        border-color $black
        &:first-child
          span
            color $facebook
        &:last-child
          span
            color $google
      &:disabled
        background-color $lightGray
        border-color $darkGray
        cursor wait
        &:first-child, &:last-child
          span
            color $darkGray

    svg, img
      height 24px
      width 24px
      margin 0 6px

    span
      font-size 14px
      font-weight bold
      line-height 25px
      margin 0 1em
      color $darkGray
      white-space nowrap

.closeBtn
  position absolute
  top 32px
  right 32px
  cursor pointer
  fill $darkGray
  &:hover
    fill $black
