@import '../colorScheme'
@import '../mediaQueries'

.nav
  position absolute
  top 50%
  padding 40px 0
  transform translateY(-50%)
  border none
  background transparent
  color $darkGray
  cursor pointer
  &:hover
    color $black
  svg
    height 50px
    width 50px
    @media(max-width $tablet)
      height 30px
      width 30px

.right
  right -50px
  @media(max-width $tablet)
    right -35px

.left
  left -50px
  @media(max-width $tablet)
    left -35px
