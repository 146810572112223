.overlay
  position fixed
  width 100%
  height 100%
  z-index -1
  background-color rgba(0, 0, 0, 0)
  transition background-color 0.5s
  &.show
    z-index 211
    background-color rgba(0, 0, 0, 0.2)
    top 0
    left 0
