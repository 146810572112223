.stop-scrolling
  height 100%
  overflow hidden

.searchOverlay
  display none
  position fixed
  top 0
  height 100%
  width 100%
  transition height 9999s, transform .3s, background-color .3s
  background-color #161616fa
  overflow auto
  z-index 200
  &.isOpening
    display block
    transform scaleX(.001)
    background-color #ffffff00
    transition none
  &.isOpen
    display block
  &.isClosing
    transform scaleX(.001)

  *
    box-sizing border-box

  button
    background transparent
    border none
    cursor pointer
    color white

  .searchContentWrapper
    width 100%
    max-width 1170px
    margin auto

  .header
    position relative
    display flex
    flex-direction row
    margin-top 1em
    margin-bottom 2em

  .close
    position absolute
    right 0
    top 0
    color white

