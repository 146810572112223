@import '../../colorScheme'
@import '../../mediaQueries'
@import '../../constants'

.suggestions
  position absolute
  top 100%
  width 100%
  margin 0
  padding 0 10px
  border 1px solid $lightGray3
  border-radius 0 0 $largeBorderRadius $largeBorderRadius
  border-top none
  color white
  background-color $black
  z-index 1000
  &.home
    top 90%
    color $black
    background-color white
    .searchItem
      color $darkGray
      padding 7px 3px
      &:hover,&.selected
        background-color $lightGray2
        .sug
          color $black
      .sug
        b
          color $black
  @media(max-width: $laptop)
    width calc(100% - 20px)
    left 10px

  .searchItem
    position relative
    width 100%
    padding .5em 40px .5em 1em
    border-radius 2px
    color $lightGray
    text-align start
    list-style-type none
    cursor pointer
    &:hover,&.selected
      background-color #4a4a4a

    .cat
      position absolute
      right 5px
      top 2px
      font-size .8em
      text-align left

    .sug
      display inline-block
      max-width 80%
      b
        color white

    &:last-child
      border-bottom none

    &.bigger
      padding-top 15px

    &:hover,&.selected
      .sug
        color white
