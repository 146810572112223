@import '../../mediaQueries'
@import '../../constants'

&.home
  margin-top 0
  .inputWrapper
    width calc(100% - 30px)
    margin 0 auto
    input[type=text]
      background white
      height 90%
      border-radius $fullBorderRadius
      color $black
      padding-left 1em
      @media(max-width $tablet)
        height 36px
    .compareBtn
      font-size 14px
      width 36%
      margin-left .5em
      align-self initial
      background-color $black
      color white
      border none
      border-radius $fullBorderRadius
      &:hover
        border 1px solid $normalGray
      @media(max-width $tablet)
        width 80%
        margin 1em auto 0
    .trash
      color $black

