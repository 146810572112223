@import '../mediaQueries'
@import '../colorScheme'

.fourhundredfour
  height 600px
  width 750px
  margin auto
  padding-top 100px
  clear padding-left

  a
    color #333
    text-decoration underline
    cursor pointer

  .code
    versus_font()
    font-size 110px
    margin 0px

  .description
    font-size 16px

  .error, .errorVsNumber
    float left
    width 44%

  .error
    text-align right

    .short
      color #EA4035

  .errorVsNumber
    color #787878
    text-align left
    margin-top 100px

    .code
      color #787878

  .short
    font-size 33px
    font-weight bold
    margin 0px

  .vs
    versus_font()
    color #d8d8d8
    float left
    font-size 33px
    font-weight bold
    margin 100px 0 0 0
    padding-top 87px
    text-align center
    width 10%

@media(max-width: 770px)
  .fourhundredfour
    .error
      .code
        font-size 80px
    .errorVsNumber
      .code
        font-size 80px

@media(max-width: $tablet)
  .fourhundredfour
    width 100%
    height 100%
    padding 50px 0
    display flex
    flex-direction row
    justify-items center
    align-items center
    .error
      margin 30px 10px 30px 20px
      padding-top 0
      width 43%
      align-self flex-start
      .code
        font-size 35px
      .short
        font-size 11px
      .description
        font-size 11px
      a
        font-size 10px
    .vs
      align-self flex-start
      font-size 25px
      margin 0
      padding 86px 0 0 0
    .errorVsNumber
      margin 30px 20px 30px 10px
      width 43%
      align-self flex-start
      .code
        font-size 35px
      .short
        font-size 11px
      .description
        font-size 11px
