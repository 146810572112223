@import '../colorScheme'
@import '../mediaQueries'
@import '../constants'

.carouselContainer
  height calc(100% - 55px)
  width calc(100% - 150px)
  margin auto
  @media(max-width $tablet)
    width calc(100% - 80px)

.closeButton
  display flex
  justify-content flex-end
  height 25px
  margin-top 1.5em
  button
    align-self center
    background transparent
    border none
    cursor pointer
    margin-right 1.5em
    color $darkGray
    &:hover
      color $black

.singleImage
  position absolute
  max-height 80%
  max-width 80%
  top 50%
  left 50%
  transform translate(-50%,-50%)
