@import '../../colorScheme'
@import '../../mediaQueries'

.suggestionsBox
  width 100%
  max-width 1000px
  margin 4em auto 0
  color white

  p
    margin-left 1em
    margin-bottom 1.5em
    font-weight bold

  .list
    display flex
    flex-wrap wrap
    width 100%

  .element
    display flex
    justify-content space-between
    width calc(50% - 2em)
    border-bottom 1px solid $darkGray
    padding 0 0 1em 1em
    margin 0 1em 1em
    @media(max-width $tablet)
      width calc(100% - 2em)
    .image
      display flex
      width 56px
      height 50px
    img
      width auto
      height auto
      max-height 50px
      max-width 56px
      margin auto
    span
      margin 0 1em
      flex-grow 2
    button
      height 26px
      min-width 60px
      border 1px solid white
      border-radius 10px
      border-bottom-right-radius 0
      margin auto
      &:hover
        border-color $normalGray
