@import '../mediaQueries'
@import '../colorScheme'

.notification
  display none
  position fixed
  top 0
  background $coolBlue
  color white
  box-shadow 0 2px 10px #666
  padding 20px 40px
  z-index 100
  left 50%
  transform translateX(-50%)
  max-width 75%
  text-align center
  border-radius 2px
  font-size .875em
  &.show
    display block
    animation-duration 8s
    animation-name hide
    opacity 0
  &.done
    display none
  .close
    background white
    border-radius 10px
    position absolute
    top 5px
    right 5px
    height 16px
    width 16px
    cursor pointer
    svg
      fill $coolBlue

@keyframes hide {
  0% {
    opacity 0
  }

  10% {
    opacity 1
  }

  90% {
    opacity 1
  }

  100% {
    opacity 0
  }
}
