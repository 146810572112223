.thumb
  position absolute
  left 50%
  top 50%
  transform translate(-50%, -50%)
  height auto
  width auto
  max-height 100%
  max-width 100%

img
  margin auto
  height auto
  width auto
  max-width 100%
  max-height 100%
